<template>
  <div>
    <h4
      style="color:#0020d1;"
      class="mt-2"
    >
      Consultation des notes :
    </h4>
    <vue-good-table
      :columns="columns"
      :rows="notes"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'username'"
          class="text-nowrap"
        >
          <b-avatar
            src="/assets/images/avatars/user.png"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.username }}</span>
        </div>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <b-form-input
                  v-model="props.row.note"
                  placeholder="Assiduite"
                  @change="setNotes(props.row.student.id, props.row.group.id, subjectIddd ,props.row.note, year, props.row.semester)"
                />
              </div>
            </div>

          </span>

        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              <b-button
                variant="primary"
                type="submit"
                class="d-flex align-items-center"
                @click.prevent="addNote"
              >
                Enregistrer les notes
              </b-button>
            </span>

          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BButton, BFormInput, BPagination,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import storeAuth from '@/store/store'
// import vSelect from 'vue-select'

// import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BButton,
    // vSelect,
    BFormInput,
    BPagination,

  },
  data() {
    return {
      load: 'false',
      pageLength: 2,
      user: {},
      academicYear: {},
      academicYears: [],
      semester: '',
      semesters: ['semester 2'],
      levels: [1, 2, 3, 4, 5, 6, 7, 8],
      columns: [
        {
          label: 'N°',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Identité',
          field: 'student.cin',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Nom & Prénom',
          field: 'student.username',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher date',
          },
        },
        {
          label: 'Présence DS',
          field: 'ds_presence',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Assiduite',
          field: 'assiduite_note',
        },
        {
          label: 'DS',
          field: 'ds_note',
        },
        {
          label: 'Examen',
          field: 'exam_note',
        },

      ],
      subjectid: '',
      notes: [],
      parcour: {},
      searchTerm: '',
      currentAcademicYear: {},
      semester1: [],
      semester2: [],
      groups: [],
      group: {},
      subjects: [],
      subjectt: [],
      subject: {},
      student: {},
      ds: '',
      year: '',
    }
  },
  computed: {
    active() {
      const statusColor = {
        true: 'light-success',
        false: 'light-danger',
      }
      return status => statusColor[status]
    },

  },
  created() {
    this.getNotes()
    this.user = storeAuth.state.user
    this.currentAcademicYear = storeAuth.state.currentAcademicYear
    this.academicYear = storeAuth.state.currentAcademicYear
    this.year = this.currentAcademicYear.id
    this.subjectid = this.$route.params.subjectid
  },
  methods: {
    async getNotes() {
      this.load = 'true'
      const response = await axios.get('/api/notes/list-note-teacher', {
        params: {
          group_id: this.$route.params.id, subject_id: this.$route.params.subjectid,
        },
      })
      // const result = response.data
      this.notes = response.data
      this.load = 'false'
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(
        2,
        '0',
      )} | ${h}`
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
